<template>
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="header-logo"></div>
        </div>
        <div v-if="isAuthenticated || isLoggedIn">
          <div v-if="loading" class="row">
            <div class="col-12">
              <ProgressSpinner />
            </div>
          </div>
          <div v-else>
            <div v-if="userHasNoFunds">
              <h4>Sorry, you are not currently part of the Revolution Fund 2.0</h4>
              <h5>If you have any questions please email <a href="mailto:admin@therevolutionfund.com">admin@therevolutionfund.com</a></h5>
            </div>
            <div v-else>
              <div style="margin-bottom:50px;">
                <div class="row fund-info hide">
                  <div class="col-lg-6 col-md-12" style="margin-bottom:20px;">
                    <div class="row"  style="margin-bottom:10px;">
                      <div class="col-lg-12">
                        <form class="flex flex-column gap-2" style="float:left;">
                          <span style="margin-right:10px;font-weight:bold">As of</span> <Calendar severity="secondary" id="date" v-model="revFundDateSelected" :minDate="minDate" :maxDate="maxDate" view="month" dateFormat="M yy" showIcon />
                          <span style="margin-left:20px;"></span>
                          <Button class="small-button-refresh" severity="secondary" type="button" label="Refresh" @click="onClick" />
                        </form>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12" >
                        <div v-if="revFundChartLoading">
                          <ProgressSpinner />
                        </div>
                        <div v-else>
                          <RevolutionFund :revolutionFundData="revolutionFund" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12" style="margin-bottom:20px;">
                    <div class="row">
                      <h5>Daily Performance
                          <Button @click="performanceChartClick('gain')" class="small-button" severity="secondary" v-bind:class="{ active: gainActive }" text label="Gain"></Button>  
                          <Button @click="performanceChartClick('totalValue')" class="small-button" severity="secondary" v-bind:class="{ active: totalValueActive }" text label="Total Value"></Button>
                      </h5>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <Button @click="performanceFilterClick('5D')" class="small-button" v-bind:class="{ active: fiveDayActive }" severity="secondary" text label="5D" />
                        <Button @click="performanceFilterClick('1M')" class="small-button" v-bind:class="{ active: oneMonthActive }" severity="secondary" text label="1M" />
                        <Button @click="performanceFilterClick('6M')" class="small-button" v-bind:class="{ active: sixMonthActive }" severity="secondary" text label="6M" />
                        <Button @click="performanceFilterClick('YTD')" class="small-button" v-bind:class="{ active: yearToDateActive }" severity="secondary" text label="YTD" />
                        <Button @click="performanceFilterClick('1Y')" class="small-button" v-bind:class="{ active: oneYearActive }" severity="secondary" text label="1Y" />
                        <Button @click="performanceFilterClick('5Y')" class="small-button" v-bind:class="{ active: fiveYearActive }" severity="secondary" text label="5Y" />
                        <Button @click="performanceFilterClick('Max')" class="small-button" v-bind:class="{ active: maxActive }" severity="secondary" text label="Max" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div v-if="totalValueActive">
                          <Chart type="line" :data="dailyChartData" :options="dailyChartOptions" />
                        </div>
                        <div v-else-if="gainActive">
                          <Chart type="line" :data="dailyChartDataGain" :options="dailyChartOptions" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-lg-12 col-md-12" style="margin-bottom:20px;">
                        <CompanyInvestment :companyFunds="companyFunds" :version="2" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12">
                        <FundList :funds="funds" :version="2" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    
                    <div class="row">  
                      <div class="col-lg-12 col-md-12">
                        <RevolutionFundChart />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <div class="footer"><h5>YOUR OWNERSHIP IN THE FUND IS PROFIT UNITS ONLY</h5></div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <div v-else>
          <div class="login-page">
            <div class="container">
              <div class="row mx-auto flex justify-content-center" style="margin-bottom:20px;width:200px;">
                  <Button @click="login" icon="pi pi-ckeck" label="Login with Okta" />
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 mx-auto">
                  <div class="card login">
                    <h1>Sign In</h1>
                    <form class="form-group">
                      <Message v-for="msg of messages" :key="msg.id" :severity="msg.severity">{{ msg.content }}</Message>
                      <input v-model="loginInput.username" type="email" class="form-control" placeholder="Email" required />
                      <input v-model="loginInput.password" type="password" class="form-control" placeholder="Password" required />
                      <Button @click="doLogin" label="Submit" :loading="authenticating"></Button>
                      <hr>
                      <p>Don't have an account? <router-link to="/register">Sign up here</router-link>
                      </p>
                      <p><router-link to="/forgotpassword">Forgot your password?</router-link></p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import FundList from '../components/FundList.vue'
  import RevolutionFund from '../components/RevolutionFund.vue'
  import CompanyInvestment from '../components/CompanyInvestments.vue'
  import RevolutionFundChart from '../components/RevolutionFundChart.vue'
  import { getAllFunds, getRevolutionFundData, getCompanyFundData, getDailyInvestmentData } from '@/services/FundService'
  import { authenticateUser } from '@/services/UserService'
  import { mapGetters, mapMutations } from "vuex"
  import { ref } from 'vue'
  
  export default {
    name: 'RevFund2',
    components: {
      FundList,
      RevolutionFund,
      CompanyInvestment,
      RevolutionFundChart
    },
    methods: {
      ...mapMutations(["setUser", "setToken", "resetState", "setWorkdayId"]),
      async login () {
        await this.$auth.signInWithRedirect({ originalUri: '/' });
      },
      performanceChartClick (chartName) {
        switch(chartName)
        {
          case 'totalValue':
            this.totalValueActive = true;
            this.gainActive = false;
            break;
          case 'gain':
            this.totalValueActive = false;
            this.gainActive = true;
            break;
        }
      },
      performanceFilterClick (timeScale) {
  
        let today = new Date();
        let dateFilter = new Date();
  
        switch(timeScale)
        {
          case '5D':
            this.fiveDayActive = true;
            this.oneMonthActive = false;
            this.sixMonthActive = false;
            this.yearToDateActive = false;
            this.oneYearActive = false;
            this.fiveYearActive = false;
            this.maxActive = false;
  
            dateFilter = new Date(today.setDate(today.getDate() - 5));
  
            break;
          case '1M':
            this.fiveDayActive = false;
            this.oneMonthActive = true;
            this.sixMonthActive = false;
            this.yearToDateActive = false;
            this.oneYearActive = false;
            this.fiveYearActive = false;
            this.maxActive = false;
  
            dateFilter = today.setMonth(today.getMonth() - 1);
  
            break;
          case '6M':
            this.fiveDayActive = false;
            this.oneMonthActive = false;
            this.sixMonthActive = true;
            this.yearToDateActive = false;
            this.oneYearActive = false;
            this.fiveYearActive = false;
            this.maxActive = false;
  
            dateFilter = today.setMonth(today.getMonth() - 6);
  
            break;
          case 'YTD':
            this.fiveDayActive = false;
            this.oneMonthActive = false;
            this.sixMonthActive = false;
            this.yearToDateActive = true;
            this.oneYearActive = false;
            this.fiveYearActive = false;
            this.maxActive = false;
  
            dateFilter = new Date(today.getFullYear(), 0, 1);
  
            break;
          case '1Y':
            this.fiveDayActive = false;
            this.oneMonthActive = false;
            this.sixMonthActive = false;
            this.yearToDateActive = false;
            this.oneYearActive = true;
            this.fiveYearActive = false;
            this.maxActive = false;
  
            dateFilter = dateFilter = today.setYear(today.getYear() - 1);
  
            break;
          case '5Y':
            this.fiveDayActive = false;
            this.oneMonthActive = false;
            this.sixMonthActive = false;
            this.yearToDateActive = false;
            this.oneYearActive = false;
            this.fiveYearActive = true;
            this.maxActive = false;
  
            dateFilter = dateFilter = today.setYear(today.getYear() - 5);
  
            break;
          case 'Max':
            this.fiveDayActive = false;
            this.oneMonthActive = false;
            this.sixMonthActive = false;
            this.yearToDateActive = false;
            this.oneYearActive = false;
            this.fiveYearActive = false;
            this.maxActive = true;
  
            dateFilter = dateFilter = today.setYear(today.getYear() - 100);
  
            break;
          
        }

        this.dailyChartData = this.setDailyChartData(dateFilter);
        this.dailyChartDataGain = this.setDailyChartDataGain(dateFilter);
  
      },
      getFunds(authToken, workDayId) {
        getAllFunds(authToken, workDayId, this.dateSelectedString.replace(/\//g,'-'), "/newfunds").then(res => {
          this.funds = res;
          for(let i = 0; i < this.funds.length; i++)
          {
            for(let j = 0; j < this.companyFunds.length; j++)
            {
  
              if(this.funds[i].fundName == this.companyFunds[j].fund)
              {
                this.funds[i].investmentAmount = this.companyFunds[j].investmentAmount;
                this.funds[i].currentValue = this.companyFunds[j].currentValue;
                this.funds[i].equity = this.companyFunds[j].equity;
                this.funds[i].totalShares = this.companyFunds[j].profitShares;
                this.funds[i].vestedValue = this.companyFunds[j].vestedValue * this.funds[i].numberOfShares;
                this.funds[i].unvestedValue = this.companyFunds[j].unvestedValue * this.funds[i].numberOfShares;
                this.funds[i].paidOut = this.companyFunds[j].paidOut * this.funds[i].numberOfShares;

                this.funds[i].leftToPay = this.companyFunds[j].leftToPay * this.funds[i].numberOfShares;
              }
            }
          }
          this.numberOfFunds = this.funds.length
          if(this.numberOfFunds > 0)
          {
            this.userHasNoFunds = false;
          }
          this.loading = false;
        });
      },
      getRevolutionFund(authToken, refresh) {
        getRevolutionFundData(authToken, this.revFundDateSelectedString.replace(/\//g,'-'), '/newfunds').then(res => {
          this.revolutionFund = res;
          if(refresh==true) this.revFundChartLoading = false;
        });
      },
      getDailyInvestments(authToken) {
        getDailyInvestmentData(authToken, this.dateSelectedString.replace(/\//g,'-'), '/newfunds').then(res => {
          this.dailyInvestmentData = res;
  
          for(let i = 0; i < this.dailyInvestmentData.length; i++)
          {
  
            var date = new Date(Date.parse(this.dailyInvestmentData[i].rowDate));
  
            if(this.isLastDay(date) || i == this.dailyInvestmentData.length -1)
            {
              this.costAndFMVLabels.push(date.toLocaleDateString('en-us', {month: 'short', year: 'numeric'}));
              this.costData.push(this.dailyInvestmentData[i].revolutionFundCost);
              this.fmvData.push(this.dailyInvestmentData[i].revolutionFundValue);
            }
          }
          this.performanceFilterClick('1M');
        });
      },
      isLastDay(dt) {
        var test = new Date(dt.getTime()),
        month = test.getMonth();
  
        test.setDate(test.getDate() + 1);
        return test.getMonth() !== month;
      },
      getCompanyFunds(authToken) {
        getCompanyFundData(authToken, this.dateSelectedString.replace(/\//g,'-'), '/newfunds').then(res => {
          this.companyFunds = res;
        });
      },
      onClick() {
        this.revFundChartLoading = true;
  
        let date = new Date(this.dateSelected);
        this.dateSelectedString = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + date.getFullYear()
  
        let d = new Date(this.revFundDateSelected);
        this.revFundDateSelectedString = ((d.getMonth() > 8) ? (d.getMonth() + 1) : ('0' + (d.getMonth() + 1))) + '/' + d.getFullYear()
  
  
        let authToken;
  
        if(this.isLoggedIn){
          authToken = this.$store.state.token;
        } else {
          authToken = this.$auth.getAccessToken();
        }
  
        this.getRevolutionFund(authToken, true);
  
      },
      setChartData() {
        const documentStyle = getComputedStyle(document.documentElement);
  
        const test = (ctx) => {
  
          var dataIndex = ctx.p0DataIndex;
          var segmentEndAmount = ctx.p1.raw;
          if(segmentEndAmount >= this.costData[dataIndex])
          {
            return documentStyle.getPropertyValue('--green-500');
          }
          else
          {
            return documentStyle.getPropertyValue('--red-500');
          }
          
        }
  
        return {
            labels: this.costAndFMVLabels,
            datasets: [
                {
                    label: 'Cost',
                    data: this.costData,
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    tension: 0
                },
                {
                    label: 'Total Value',
                    data: this.fmvData,
                    fill: false,
                    borderColor: documentStyle.getPropertyValue('--green-500'),
                    segment: {
                      borderColor: ctx => test(ctx),
                    },
                    tension: 0
                }
            ]
        };
      },
      setDailyChartData(dateFilter) {
        this.dailyLabels = [];
        this.dailyValue = [];
  
        const documentStyle = getComputedStyle(document.documentElement);
  
        var newDailyInvestmentData = this.dailyInvestmentData;
  
        for(let i = 0; i < newDailyInvestmentData.length; i++)
        {
          var date = new Date(Date.parse(newDailyInvestmentData[i].rowDate));
  
          if(date > dateFilter){
            this.dailyLabels.push(date.toLocaleDateString('en-us', {day: '2-digit', month: 'short'}));
            this.dailyValue.push(newDailyInvestmentData[i].revolutionFundValue);
          }
          
        }
  
        return {
            labels: this.dailyLabels,
            datasets: [
                {
                    label: 'Revolution Fund Value',
                    data: this.dailyValue,
                    fill:true,
                    borderColor: documentStyle.getPropertyValue('--orange-500'),
                    backgroundColor: 'rgba(255,167,38,0.2)',
                    tension: 0,
                }
            ]
        };
      },
      setDailyChartDataGain(dateFilter) {
        this.dailyGain = [];
  
        var newDailyInvestmentData = this.dailyInvestmentData;
  
        for(let i = 0; i < newDailyInvestmentData.length; i++)
        {
          var date = new Date(Date.parse(newDailyInvestmentData[i].rowDate));
  
          if(date > dateFilter){
            this.dailyGain.push(newDailyInvestmentData[i].gain);
          }
          
        }
  
        return {
            labels: this.dailyLabels,
            datasets: [
                {
                  label: 'Gain',
                  data: this.dailyGain,
                  fill: {
                      target: 'origin',
                      above: 'rgba(95,175,101,0.8',
                      below: 'rgba(208,92,93,0.8)'
                    },
                  borderColor: 'rgba(145, 147, 152, 1)',
                  tension: 0,
                }
            ]
        };
      },
      setDailyChartOptions() {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = '#2c3e50';
        const textColorSecondary = '#2c3e50';
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
  
        return {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
              callbacks: {
                  label: function(context) {
                    let label = context.dataset.label || '';
  
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += '$' + context.parsed.y.toLocaleString();
              }
              return label;
                  }
                }
              },
                legend: {
                    labels: {
                        color: textColor,
                        font: {
                          family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          size: 14
                        }
                    }
                },
                title: {
                  display: false,
                  text: 'Daily Performance',
                  font: {
                          size: 18,
                          family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          color: '#2c3e50 !important'
                        }
                },
            },
            scales: {
                x: {
                    ticks: {
                      maxRotation: 45,
                      minRotation: 45,
                      color: textColorSecondary,
                      font: {
                        size: 14,
                        family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
                      }
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                          size: 14,
                          family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
                        },
                      callback: function(value) {
                        return '$' + value.toLocaleString();
                      }
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };
      },
      setChartOptions() {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = '#2c3e50';
        const textColorSecondary = '#2c3e50';
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
  
        return {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
              callbacks: {
                  label: function(context) {
                    let label = context.dataset.label || '';
  
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += '$' + context.parsed.y.toLocaleString();
              }
              return label;
                  }
                }
              },
                legend: {
                    labels: {
                        color: textColor,
                        font: {
                          family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          size: 14
                        }
                    }
                },
                title: {
                  display: false,
                  text: 'Cost and Total Value',
                  font: {
                          size: 18,
                          family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                          color: '#2c3e50 !important'
                        }
                },
            },
            scales: {
                x: {
                    ticks: {
                      maxRotation: 45,
                      minRotation: 45,
                        color: textColorSecondary,
                        font: {
                          size: 14,
                          family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
                        }
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                          size: 14,
                          family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
                        },
                      callback: function(value) {
                        return '$' + value.toLocaleString();
                      }
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };
      },
      async doLogin () {
  
        if(this.loginInput.username == '' || this.loginInput.password == '') {
          this.messages = [
            { severity: 'error', content: 'Email and Password are required.', id: this.messagesCount++ }
          ];
          return;
        }
  
        if(!this.validateEmail()) {
          this.messages = [
            { severity: 'error', content: 'Please enter a valid email address.', id: this.messagesCount++ }
          ];
          return;
        }
  
        this.authenticating = true;
        var authorized = await authenticateUser(this.loginInput.username, this.loginInput.password);
  
        if(authorized){
  
          this.authState.accessToken = authorized.token;
          this.authState.idToken = "";
          this.authState.isAuthenticated = true;
          this.isCustomAuthorized = true;
  
          this.setUser(this.loginInput.username);
          this.setToken(authorized.token);
          this.setWorkdayId(authorized.workdayId);
          this.authenticating = false;
          this.loadpage();
        }
        else {
          this.authenticating = false;
          this.messages = [
            { severity: 'error', content: 'Invalid login', id: this.messagesCount++ }
          ];
        }
      },
      async loadpage() {
  
        if(this.isAuthenticated || this.isLoggedIn){
        
          let date = new Date();
          this.dateSelected = date.toLocaleDateString('en-us', {month: 'short', year: 'numeric'});
          this.dateSelectedString = this.dateSelected;
          this.dateSelected = new Date();
        
          let revFundDate = new Date();
        
          // check if today is last day of current month
          if(!this.isLastDay(revFundDate)){
            revFundDate.setDate(1);
            revFundDate.setHours(-1);
          }
          this.revFundDateSelected = revFundDate;
          this.revFundDateSelected = revFundDate.toLocaleDateString('en-us', {month: 'short', year: 'numeric'});
          this.revFundDateSelectedString = this.revFundDateSelected;
  
          let revFundDateNew = new Date();
        
          // check if today is last day of current month
          if(!this.isLastDay(revFundDateNew)){
            revFundDateNew.setDate(1);
            revFundDateNew.setHours(-1);
          }
          this.revFundDateSelected = revFundDateNew;
        
          let authToken = "";
          if(this.isLoggedIn) {
            this.workDayId = this.$store.state.workdayId;
            this.email = this.$store.state.user;
            authToken = this.$store.state.token;
          } else {
            const idToken = await this.$auth.tokenManager.get('idToken');
            let myClaims = await Object.entries(idToken.claims).map(entry => ({ claim: entry[0], value: entry[1] }));
            let myNameClaim = myClaims.find(obj => {
              return obj.claim === 'name'
            });
            let user = await this.$auth.getUser();
            this.myName = myNameClaim.value;
            this.workDayId = user['EID-employeeNumber'];
            this.email = user['email'];
            this.setUser(this.email);
            authToken = this.$auth.getAccessToken();
          }
  
          this.getCompanyFunds(authToken);
          this.getRevolutionFund(authToken);
          this.getDailyInvestments(authToken);
          this.getFunds(authToken, this.workDayId);
  
          this.minDate = new Date('1/1/2023');
          let today = new Date();
          today.setMonth(today.getMonth() - 1);
          this.maxDate = today;
  
          this.chartData = this.setChartData();
          this.chartOptions = this.setChartOptions();
  
          this.dailyChartOptions = this.setDailyChartOptions();
        }
      },
      validateEmail() {
        if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.loginInput.username)) {
            return false;
        } else {
            return true;
        }
      },
    },
    data () {
      return {
        myName: null,
        workDayId: null,
        email: null,
        funds: [],
        companyFunds: [],
        revolutionFund: [],
        numberOfFunds: 0,
        dateSelected: null,
        dateSelectedString: null,
        revFundDateSelected: null,
        revFundDateSelectedString: null,
        loading: true,
        revFundChartLoading: false,
        chartData: null,
        chartOptions: null,
        dailyChartData: null,
        dailyChartDataGain: null,
        dailyChartOptions: null,
        dailyInvestmentData: [],
        costAndFMVLabels: [],
        costData: [],
        fmvData: [],
        dailyLabels: [],
        dailyValue: [],
        dailyGain: [],
        userHasNoFunds: true,
        filterStyle: {
          background:"#dfe4ea"
        },
        fiveDayActive: true,
        oneMonthActive: false,
        sixMonthActive: false,
        yearToDateActive: false,
        oneYearActive: false,
        fiveYearActive: false,
        maxActive: false,
        totalValueActive: false,
        gainActive: true,
        minDate: null,
        maxDate:null,
        loginInput: {
          username: "",
          password: ""
        },
        isCustomAuthorized: false,
        messages: ref([]),
        messagesCount: ref(0),
        authenticating: false
      }
    },
    computed: {
      ...mapGetters(["isLoggedIn"]),
      isAuthenticated () {
        return ((this.authState && this.authState.isAuthenticated));
      }
    },
    async mounted () {
  
      if(this.$route.query.loginmethod == 'okta') {
        this.login();
      }
  
      this.loadpage();
  
    }
  }
  </script>
  
  <style>
  .card {
     padding: 20px;
  }
  
  .form-group input {
      margin-bottom: 20px;
      margin-top:20px
  }
  
  .login-page {
    margin-top:30px;
     align-items: center;
     display: flex;
     text-align:left;
  }
  </style>