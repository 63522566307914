import { createRouter, createWebHistory } from 'vue-router'
import { LoginCallback, navigationGuard } from '@okta/okta-vue'
import ProfileComponent from '@/components/MyProfile'
import HomeView from '../views/HomeView.vue'
import RevFund2 from '../views/RevFund2.vue'
import RegisterPage from '@/components/RegisterPage'
import ForgotPassword from '@/components/ForgotPassword'

const routes = [
  {
    path: '/revfundold',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    name: 'revfund2',
    component: RevFund2
  },
  {
    path: '/login/callback',
    component: LoginCallback
  },
  {
    path: '/profile',
    component: ProfileComponent,
  },
  {
    path: '/register',
    component: RegisterPage
  },
  {
    path: '/forgotPassword',
    component: ForgotPassword
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(navigationGuard)
export default router
