<template>
    <h5>Revolution Fund Investment</h5>
    <div class="row">
      <div class="col-lg-12">
        <Button @click="performanceFilterClick('5D')" class="small-button" v-bind:class="{ active: fiveDayActive }" severity="secondary" text label="5D" />
        <Button @click="performanceFilterClick('1M')" class="small-button" v-bind:class="{ active: oneMonthActive }" severity="secondary" text label="1M" />
        <Button @click="performanceFilterClick('6M')" class="small-button" v-bind:class="{ active: sixMonthActive }" severity="secondary" text label="6M" />
        <!-- <Button @click="performanceFilterClick('YTD')" class="small-button" v-bind:class="{ active: yearToDateActive }" severity="secondary" text label="YTD" /> -->
        <Button @click="performanceFilterClick('1Y')" class="small-button" v-bind:class="{ active: oneYearActive }" severity="secondary" text label="1Y" />
        <Button @click="performanceFilterClick('5Y')" class="small-button" v-bind:class="{ active: fiveYearActive }" severity="secondary" text label="5Y" />
        <Button @click="performanceFilterClick('Max')" class="small-button" v-bind:class="{ active: maxActive }" severity="secondary" text label="Max" />
      </div>
    </div>
    <Chart type="line" :data="revFundChartData" :options="revFundChartOptions" class="h-30rem" />
</template>

<script>
    import { getDailyInvestmentDataForRevFund } from '@/services/FundService'

    export default {
        name: 'RevolutionFundChart',
        data() {
            return {
                revFundChartData: null,
                revFundChartOptions: null,
                dailyInvestmentData: null,
                fiveDayActive: true,
                oneMonthActive: false,
                sixMonthActive: false,
                yearToDateActive: false,
                oneYearActive: false,
                fiveYearActive: false,
                maxActive: false,
            };
        },
        async mounted() {
            let date = new Date();
            let dateSelectedString = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + date.getFullYear()
            let authToken = this.$store.state.token;

            await this.getDailyInvestmentForRevFund(authToken, dateSelectedString);

            //this.revFundChartData = this.setRevFundChartData(date);
            this.revFundChartOptions = this.setRevFundChartOptions();
        },
        methods: {
            performanceFilterClick (timeScale) {
  
                let today = new Date();
                let dateFilter = new Date();

                switch(timeScale)
                {
                  case '5D':
                    this.fiveDayActive = true;
                    this.oneMonthActive = false;
                    this.sixMonthActive = false;
                    this.yearToDateActive = false;
                    this.oneYearActive = false;
                    this.fiveYearActive = false;
                    this.maxActive = false;
                
                    dateFilter = new Date(today.setDate(today.getDate() - 5));
                
                    break;
                  case '1M':
                    this.fiveDayActive = false;
                    this.oneMonthActive = true;
                    this.sixMonthActive = false;
                    this.yearToDateActive = false;
                    this.oneYearActive = false;
                    this.fiveYearActive = false;
                    this.maxActive = false;
                
                    dateFilter = today.setMonth(today.getMonth() - 1);
                
                    break;
                  case '6M':
                    this.fiveDayActive = false;
                    this.oneMonthActive = false;
                    this.sixMonthActive = true;
                    this.yearToDateActive = false;
                    this.oneYearActive = false;
                    this.fiveYearActive = false;
                    this.maxActive = false;
                
                    dateFilter = today.setMonth(today.getMonth() - 6);
                
                    break;
                  case 'YTD':
                    this.fiveDayActive = false;
                    this.oneMonthActive = false;
                    this.sixMonthActive = false;
                    this.yearToDateActive = true;
                    this.oneYearActive = false;
                    this.fiveYearActive = false;
                    this.maxActive = false;
                
                    dateFilter = new Date(today.getFullYear(), 0, 1);
                
                    break;
                  case '1Y':
                    this.fiveDayActive = false;
                    this.oneMonthActive = false;
                    this.sixMonthActive = false;
                    this.yearToDateActive = false;
                    this.oneYearActive = true;
                    this.fiveYearActive = false;
                    this.maxActive = false;

                    dateFilter = new Date(today.setFullYear(today.getFullYear() - 1));
                
                    break;
                  case '5Y':
                    this.fiveDayActive = false;
                    this.oneMonthActive = false;
                    this.sixMonthActive = false;
                    this.yearToDateActive = false;
                    this.oneYearActive = false;
                    this.fiveYearActive = true;
                    this.maxActive = false;
                
                    dateFilter = dateFilter = today.setYear(today.getYear() - 5);
                
                    break;
                  case 'Max':
                    this.fiveDayActive = false;
                    this.oneMonthActive = false;
                    this.sixMonthActive = false;
                    this.yearToDateActive = false;
                    this.oneYearActive = false;
                    this.fiveYearActive = false;
                    this.maxActive = true;
                
                    dateFilter = dateFilter = today.setYear(today.getYear() - 100);
                
                    break;
                }
                this.revFundChartData = this.setRevFundChartData(dateFilter)
            },
            isLastDayOfMonth(dt) {
                var test = new Date(dt.getTime()),
                month = test.getMonth();

                test.setDate(test.getDate() + 1);
                return test.getMonth() !== month;
            },
            isLastDayOfQuarter(dt) {
                const nonEOQMonths = [0,1,3,4,6,7,9,10]
                var test = new Date(dt.getTime()),
                month = test.getMonth();

                if(nonEOQMonths.includes(month)){
                    return false;
                }

                test.setDate(test.getDate() + 1);
                return test.getMonth() !== month;
            },
            setRevFundChartData(dateFilter) {
                this.dailyLabels = [];
                this.dailyCost = [];
                this.dailyValue = [];
                this.dailyTotal = [];

                const documentStyle = getComputedStyle(document.documentElement);

                var newDailyInvestmentData = this.dailyInvestmentData;

                for(let i = 0; i < newDailyInvestmentData.length; i++)
                {
                  var date = new Date(Date.parse(newDailyInvestmentData[i].rowDate));
                
                  if(this.sixMonthActive || this.oneYearActive || this.yearToDateActive) {
                    if(date > dateFilter && this.isLastDayOfMonth(date)){
                        this.dailyLabels.push(date.toLocaleDateString('en-us', {day: '2-digit', month: 'short'}));
                        this.dailyCost.push(newDailyInvestmentData[i].revolutionFundCost);
                        this.dailyValue.push(newDailyInvestmentData[i].revolutionFundValue);
                        this.dailyTotal.push(newDailyInvestmentData[i].revolutionFundValue + newDailyInvestmentData[i].revolutionFundPayout);
                    }
                  }
                  else if (this.fiveYearActive || this.maxActive) {
                    if(date > dateFilter && (this.isLastDayOfQuarter(date) || i==0 || i==newDailyInvestmentData.length-1)){
                        this.dailyLabels.push(date.toLocaleDateString('en-us', {year: "numeric", day: '2-digit', month: 'short'}));
                        this.dailyCost.push(newDailyInvestmentData[i].revolutionFundCost);
                        this.dailyValue.push(newDailyInvestmentData[i].revolutionFundValue);
                        this.dailyTotal.push(newDailyInvestmentData[i].revolutionFundValue + newDailyInvestmentData[i].revolutionFundPayout);
                    }
                  }
                  else {
                    if(date > dateFilter){
                        this.dailyLabels.push(date.toLocaleDateString('en-us', {day: '2-digit', month: 'short'}));
                        this.dailyCost.push(newDailyInvestmentData[i].revolutionFundCost);
                        this.dailyValue.push(newDailyInvestmentData[i].revolutionFundValue);
                        this.dailyTotal.push(newDailyInvestmentData[i].revolutionFundValue + newDailyInvestmentData[i].revolutionFundPayout);
                    }
                  }


                  

                  

                }
            
                return {
                    labels: this.dailyLabels,
                    datasets: [
                        {
                            label: 'Cost',
                            data: this.dailyCost,
                            borderColor: documentStyle.getPropertyValue('--blue-500'),
                            tension: 0.5,
                        },
                        {
                            label: 'Value',
                            data: this.dailyValue,
                            borderColor: documentStyle.getPropertyValue('--red-500'),
                            tension: 0.5,
                        },
                        {
                            label: 'Total (Value + Payout)',
                            data: this.dailyTotal,
                            borderColor: documentStyle.getPropertyValue('--orange-500'),
                            tension: 0.5,
                        }
                    ]
                };
            },
            setRevFundChartOptions() {
                const documentStyle = getComputedStyle(document.documentElement);
                const textColor = documentStyle.getPropertyValue('--text-color');
                const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
                const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

                return {
                    maintainAspectRatio: false,
                    aspectRatio: 0.6,
                    plugins: {
                        legend: {
                            labels: {
                                color: textColor
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: textColorSecondary
                            },
                            grid: {
                                color: surfaceBorder
                            }
                        },
                        y: {
                            ticks: {
                                color: textColorSecondary
                            },
                            grid: {
                                color: surfaceBorder
                            }
                        }
                    }
                };
            },
            async getDailyInvestmentForRevFund(authToken, dateSelected) {
                await getDailyInvestmentDataForRevFund(authToken, dateSelected.replace(/\//g,'-'), '/newfunds').then(res => {
                    this.dailyInvestmentData = res;
                    this.performanceFilterClick('1M');
                });
            },
        }
    }
</script>