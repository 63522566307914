<template>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Claim</th>
          <th scope="col">Value</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(claim, index) in claims"
          :key="index"
        >
          <td>{{claim.claim}}</td>
          <td :id="'claim-' + claim.claim">{{claim.value}}</td>
        </tr>
      </tbody>
    </table>
  </template>
  
  <script>
  export default {
    name: 'MyProfile',
    data () {  
      return {
        claims: []
      }
    },
    async created () {
      const idToken = await this.$auth.tokenManager.get('idToken')
      this.claims = await Object.entries(idToken.claims).map(entry => ({ claim: entry[0], value: entry[1] }))
    }
  }
  </script>
  