export default {
    // oidc: {
    //   clientId: '0oa8m4mcagQSfhuV95d7',
    //   issuer: 'https://dev-99388924.okta.com/oauth2/default',
    //   redirectUri: window.location.origin + '/login/callback',
    //   scopes: ['openid', 'profile', 'email']
    // }
    oidc: {
      clientId: '0oa8m61ns6vJ0Uegt5d7',
      issuer: 'https://roguefitness.okta.com/oauth2/default',
      redirectUri: window.location.origin + '/login/callback',
      scopes: ['openid', 'profile', 'email']
    }
  }