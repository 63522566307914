<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <img src="../src/assets/Rogue_Primary_Registered_RGB_Black.png" alt="Rogue Fitness" class="original-logo" style="margin:5px;height:24px;margin-top:10px;">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          
        </li>
      </ul>
      <div v-if="this.getEmail" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{this.getEmail}}</a>
          <ul class="dropdown-menu dropdown-menu-lg-end" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" @click="logout" href="#">Logout</a></li>
          </ul>
      </div>
    </div>
  </div>
</nav>
<div class="container-fluid">
  <router-view/>
</div>
  
  
</template>

<script>
import { mapGetters, mapMutations } from "vuex"

export default {
  methods: {
    ...mapMutations(["resetState"]),
    async logout () {
      await this.$auth.signOut();
      this.resetState();
    },
  },
  data () {
    return {
      email: null,
    }
  },
  computed: {
    ...mapGetters(["getEmail"])
  },
  

}


</script>

<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.p-datatable .p-datatable-tbody>tr>td, .p-datatable .p-datatable-thead>tr>th {
  padding:0.3rem!important;
}

.p-chart {
  height:250px !important;
}

.costAndFMV {
  height:200px !important;
}

.p-column-header-content .p-column-title {
  width:100% !important;
}

.p-datatable-tbody tr td:not(.leftHeader), .p-datatable-thead tr th:not(.leftHeader) {
  text-align: center !important;
}

.leftHeader {
  font-weight:bold;
}

.header-logo {
  height:155px;
  background-position:center;
  background-image: url('@/assets/rev-fund-2.jpg');
  margin-bottom:10px;
}

.small-button {
  font-size:12px !important;
  padding:5px 10px !important;
  margin-right:7px !important;
}

.small-button-refresh {
  font-size:12px !important;
  padding:10px 20px !important;
}

.p-calendar .p-button {
  font-size:12px !important;
  padding:10px 20px !important;
}

.p-calendar .p-inputtext {
  font-size:12px !important;
  padding:10px 20px !important;
  width:100px !important;
}

.p-datepicker-trigger {
    color: #ffffff !important;
    background: #64748B !important;
    border: 1px solid #64748B !important;
}

.p-inputtext:enabled:hover {
  border-color:#ced4da !important;
}

.active {
  background:#64748B !important;
  color: #ffffff !important;
}

.footer h5 {
  border: 1px solid #ced4da;
  padding:10px;
  margin-bottom:20px;
  font-size:18px !important;
  font-weight:500;
}

@media screen and (max-width: 480px) {
  .p-datatable .p-datatable-tbody>tr>td, .p-datatable .p-datatable-thead>tr>th {
    font-size:12px !important;
  }
  .footer h5 {
    font-size:18px !important;
  }
}


</style>
