<template>
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="header-logo"></div>
        </div>
      </div>
    </div>

    <div class="forgot-password-page">
        <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-4 mx-auto">
                <div class="card login">
                    <h1>Forgot Password</h1>
                    <form class="form-group">
                      <Message v-for="msg of messages" :key="msg.id" :severity="msg.severity">{{ msg.content }}</Message>
                      <div v-if="updatePasswordSuccessful">
                      </div>
                      <div v-else-if="forgotPasswordEmailSent">
                        <input v-model="validationCode" class="form-control" placeholder="Validation code" required />
                        <input v-model="password" type="password" class="form-control" placeholder="New password" required />
                        <input v-model="confirmPassword" type="password" class="form-control" placeholder="Confirm new password" required />
                        <Button @click="updatePasswordSubmit" :loading="submitting">Submit</Button>
                      </div>
                      <div v-else>
                        <input v-model="username" type="email" class="form-control" placeholder="Email" required />
                        <Button @click="forgotPasswordSubmit" :loading="submitting">Submit</Button>
                      </div>
                      
                    </form>
                    <hr>
                    <p><router-link to="/">Back to Login</router-link></p>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>

import { forgotpassword, updatepassword } from '@/services/UserService';
import { ref } from 'vue'

export default {
    data () {
        return {
            username: '',
            validationCode: '',
            password: '',
            confirmPassword: '',
            messages: ref([]),
            messagesCount: ref(0),
            forgotPasswordEmailSent: false,
            updatePasswordSuccessful: false,
            submitting: false,
        }
    },
    methods: {
        async forgotPasswordSubmit() {
          if(this.username == '') {
            this.messages = [
              { severity: 'error', content: 'Email is required.', id: this.messagesCount++ }
            ];
            return;
          }

          if(!this.validateEmail()) {
            this.messages = [
              { severity: 'error', content: 'Please enter a valid email address.', id: this.messagesCount++ }
            ];
            return;
          }

          this.submitting = true;

          var forgotPasswordResult = await forgotpassword(this.username);

          console.log('forgotPasswordResult');
          console.log(forgotPasswordResult);

          this.forgotPasswordEmailSent = true;
          this.submitting = false;
          this.messages = [
            { severity: 'success', content: 'Please check your email for the 6 digit validation code and enter below.', id: this.messagesCount++ }
          ];

        },
        async updatePasswordSubmit() {
            if(this.validationCode == '' || this.password == '' || this.confirmPassword == '') {
                this.messages = [
                { severity: 'error', content: 'All fields are required.', id: this.messagesCount++ }
                ];
                return;
            }

            if(this.validationCode.length !== 6){
              this.messages = [
                { severity: 'error', content: 'Validation code must be 6 digits.', id: this.messagesCount++ }
              ];
              return;
            }

            if(!this.validatePassword()) {
              this.messages = [
                { severity: 'error', content: 'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.', id: this.messagesCount++ }
              ];
              return;
            }

            if(this.password !== this.confirmPassword) {
              this.messages = [
                { severity: 'error', content: 'Password and Confirm Password do not match.', id: this.messagesCount++ }
              ];
              return;
            }

            this.submitting = true;

            var updatePasswordResult = await updatepassword(this.username, this.password, this.validationCode);

            this.submitting = false;

            console.log('updatePasswordResult');
            console.log(updatePasswordResult);

            if(updatePasswordResult.includes('User password successfully updated')) {
                this.updatePasswordSuccessful = true;
                this.messages = [
                    { severity: 'success', content: 'Password has been updated successfully.', id: this.messagesCount++ }
                ];
            } else {
                this.messages = [
                    { severity: 'error', content: updatePasswordResult, id: this.messagesCount++ }
                ];
            }
            
        },
        validateEmail() {
          if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.username)) {
              return false;
          } else {
              return true;
          }
        },
        validatePassword() {
          const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
          if(!passwordRegex.test(this.password)) {
            return false;
          } else {
            return true;
          }
        }
    }
}

</script>

<style>
.card {
   padding: 20px;
}

.form-group input {
    margin-bottom: 20px;
    margin-top:20px;
}

.forgot-password-page {
  margin-top:30px;
   align-items: center;
   display: flex;
   text-align:left;
}
</style>