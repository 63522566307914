<template>
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="header-logo"></div>
        </div>
      </div>
    </div>
    
    <div class="register-page">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-4 mx-auto">
                <div class="card login">
                    <h1>Sign up</h1>
                    <form class="form-group">
                      <Message v-for="msg of messages" :key="msg.id" :severity="msg.severity">{{ msg.content }}</Message>
                      <div v-if="registrationSuccessful">
                      </div>
                      <div v-else-if="registrationVerified">
                        <input v-model="validationCode" class="form-control" placeholder="Validation code" required />
                        <Button @click="submitValidation" :loading="submitting">Submit</Button>
                      </div>
                      <div v-else>
                        <input v-model="loginInput.username" type="email" class="form-control" placeholder="Email" required />
                        <input v-model="loginInput.password" type="password" class="form-control" placeholder="Password" required />
                        <input v-model="loginInput.confirmPassword" type="password" class="form-control" placeholder="Confirm Password" required />
                        <Button @click="doLogin" :loading="submitting">Submit</Button>
                      </div>
                    </form>
                    <hr>
                    <p><router-link to="/">Back to Login</router-link></p>
                </div>
              </div>
            </div>
          </div>
        </div>

</template>

<script>

import { verifyRegistration, registerUser } from '@/services/UserService';
import { ref } from 'vue'

export default {
    data () {
        return {
            loginInput: {
                username: '',
                password: '',
                confirmPassword: '',
            },
            validationCode: '',
            submitted: false,
            messages: ref([]),
            messagesCount: ref(0),
            registrationVerified: false,
            registrationSuccessful: false,
            submitting: false,
        }
    },
    computed: {
        
    },
    methods: {
        
        async doLogin() {
          if(this.loginInput.username == '' || this.loginInput.password == '' || this.loginInput.confirmPassword == '') {
            this.messages = [
              { severity: 'error', content: 'All fields are required.', id: this.messagesCount++ }
            ];
            return;
          }

          if(!this.validateEmail()) {
            this.messages = [
              { severity: 'error', content: 'Please enter a valid email address.', id: this.messagesCount++ }
            ];
            return;
          }

          if(!this.validatePassword()) {
            this.messages = [
              { severity: 'error', content: 'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.', id: this.messagesCount++ }
            ];
            return;
          }

          if(this.loginInput.password !== this.loginInput.confirmPassword) {
            this.messages = [
              { severity: 'error', content: 'Password and Confirm Password do not match.', id: this.messagesCount++ }
            ];
            return;
          }

          this.submitting = true;

          var registerResult = await verifyRegistration(this.loginInput.username);

          console.log('registerResult');
          console.log(registerResult);

          if(registerResult.includes('Validation code has been emailed')) {
            this.submitting = false;
            this.registrationVerified = true;
            this.messages = [
              { severity: 'success', content: 'Please check your email for the 6 digit validation code and enter below.', id: this.messagesCount++ }
            ];
          } else {
            this.submitting = false;
                this.messages = [
                    { severity: 'error', content: registerResult, id: this.messagesCount++ }
                ];
            }

        },
        async submitValidation() {
          if(this.validationCode == '') {
            this.messages = [
              { severity: 'error', content: 'Please enter validation code.', id: this.messagesCount++ }
            ];
            return;
          }

          if(this.validationCode.length !== 6){
            this.messages = [
              { severity: 'error', content: 'Validation code must be 6 digits.', id: this.messagesCount++ }
            ];
            return;
          }

          this.submitting = true;

          var registerResult = await registerUser(this.loginInput.username, this.loginInput.password, this.validationCode);

          this.submitting = false;

          if(registerResult.includes('User successfully registered')){
            this.registrationSuccessful = true;
            this.messages = [
              { severity: 'success', content: registerResult, id: this.messagesCount++ }
            ];
          } else {
            this.messages = [
              { severity: 'error', content: registerResult, id: this.messagesCount++ }
            ];
          }
        },
        validateEmail() {
          if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.loginInput.username)) {
              return false;
          } else {
              return true;
          }
        },
        validatePassword() {
          const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
          if(!passwordRegex.test(this.loginInput.password)) {
            return false;
          } else {
            return true;
          }
        }
    }
};
</script>

<style>
.card {
   padding: 20px;
}

.form-group input {
    margin-bottom: 20px;
    margin-top:20px;
}

.register-page {
  margin-top:30px;
   align-items: center;
   display: flex;
   text-align:left;
}
</style>