export async function getAllFunds(authToken, userId, theDate, version = '') {
  
  const headers = {
    'Authorization': 'Bearer ' + authToken,
  };

  const response = await fetch(process.env.VUE_APP_ROOT_API + '/api' + version + '/myfunds/' + userId + '/' + theDate, { headers });
  return await response.json();
}

export async function getRevolutionFundData(authToken, theDate, version = '') {
    const headers = {
      'Authorization': 'Bearer ' + authToken,
    };
  
    const response = await fetch(process.env.VUE_APP_ROOT_API + '/api' + version + '/revolutionfund/' + theDate, { headers });
    return await response.json();
}

export async function getCompanyFundData(authToken, theDate, version = '') {
  const headers = {
    'Authorization': 'Bearer ' + authToken,
  };

  const response = await fetch(process.env.VUE_APP_ROOT_API + '/api' + version + '/companyfunds/' + theDate, { headers });
  return await response.json();
}

export async function getDailyInvestmentData(authToken, theDate, version = '') {
  const headers = {
    'Authorization': 'Bearer ' + authToken,
  };

  const response = await fetch(process.env.VUE_APP_ROOT_API + '/api' + version + '/dailyinvestments/' + theDate, { headers });
  return await response.json();
}

export async function getDailyInvestmentDataForRevFund(authToken, theDate, version = '') {
  const headers = {
    'Authorization': 'Bearer ' + authToken,
  };

  const response = await fetch(process.env.VUE_APP_ROOT_API + '/api' + version + '/dailyinvestmentsrevfund/' + theDate, { headers });
  return await response.json();
}