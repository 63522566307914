import { createApp } from 'vue'
import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'
import App from './App.vue'
import router from './router'
import store from './store'

import PrimeVue from 'primevue/config'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Chart from 'primevue/chart';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Message from 'primevue/message';

import sampleConfig from '@/config'


import "primevue/resources/themes/lara-light-indigo/theme.css";     
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

const oktaAuth = new OktaAuth(sampleConfig.oidc)

document.title = 'Revolution Fund'

createApp(App)
    .use(router)
    .use(OktaVue, { oktaAuth })
    .use(store)
    .use(PrimeVue)
    .component('DataTable', DataTable)
    .component('Column', Column)
    .component('Chart', Chart)
    .component('Calendar', Calendar)
    .component('Button', Button)
    .component('ProgressSpinner', ProgressSpinner)
    .component('TabView', TabView)
    .component('TabPanel', TabPanel)
    .component('Message', Message)
    .mount('#app')
