import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
  state: {
    user: null,
    token: null,
    workdayId: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setWorkdayId(state, workdayId) {
      state.workdayId = workdayId;
    },
    resetState(state) {
        state.user = null;
        state.token = null;
        state.workdayId = null;
    }
  },
  actions: {},
  getters: {
    isLoggedIn(state) {
        return !!state.token;
    },
    getEmail(state) {
      return state.user;
    }
  },
  plugins: [createPersistedState()]
});