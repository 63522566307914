<template>
    <h5>Personal Investments as of {{ today }}</h5>
    <DataTable :value="funds" showGridlines tableStyle="min-width: 50rem">
        <!--<Column field="id" header="Fund Id"></Column>-->
        <Column header="Fund" class="leftHeader">
            <template #body="slotProps">
                {{ slotProps.data.fundName }}
            </template>
        </Column>
        <Column header="Investment Amount">
            <template #body="slotProps">
                {{ formatCurrency(slotProps.data.investmentAmount) }}
            </template>
        </Column>
        <Column header="Current Value">
            <template #body="slotProps">
                {{ formatCurrency(slotProps.data.currentValue) }}
            </template>
        </Column>
        <Column header="Profit Units">
            <template #body="slotProps">
                {{ formatTwoDecimal(slotProps.data.numberOfShares) }}
            </template>
        </Column>
        <Column header="% of Ownership">
            <template #body="slotProps">
                {{ formatTwoDecimal(slotProps.data.awardPct) }}
            </template>
        </Column>
        <Column header="Equity Units" v-if="version != 2">
            <template #body="slotProps">
                {{ slotProps.data.equity }}
            </template>
        </Column>
        <Column header="Unvested Value">
            <template #body="slotProps">
                {{ formatCurrencyKeepCents(slotProps.data.unvestedValue) }}
            </template>
        </Column>
        <Column header="Vested Value">
            <template #body="slotProps">
                {{ formatCurrencyKeepCents(slotProps.data.vestedValue) }}
            </template>
        </Column>
        <Column header = "Paid Out" v-if="version == 2">
            <template #body="slotProps">
                {{ formatCurrencyKeepCents(slotProps.data.paidOut) }}
            </template>
        </Column>
        <Column header = "Left to Pay" v-if="version == 2">
            <template #body="slotProps">
                {{ formatCurrencyKeepCents(slotProps.data.leftToPay) }}
            </template>
        </Column>
    </DataTable>
</template>

<script>
    export default {
        name: 'FundList',
        props: ['funds', 'companyFunds', 'version'],
        data () {
            return {
                lastDayOfLastMonth: null,
                today: null,
            }
        },
        methods: {
            formatCurrency(value) {
                return value.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 });
            },
            formatCurrencyKeepCents(value) {
                return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            },
            formatDate(dateString) {
                const date = new Date(dateString);
                // Then specify how you want your dates to be formatted
                return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
            },
            formatTwoDecimal(num) {
                return parseFloat(num).toFixed(2);
            },
            isLastDay(dt) {
                var test = new Date(dt.getTime()),
                    month = test.getMonth();

                test.setDate(test.getDate() + 1);
                return test.getMonth() !== month;
            }
        },
        async mounted () {
            var d = new Date();
            this.today = d.toLocaleDateString('en-us', {year: 'numeric', month: 'long', day: 'numeric'});
            // check if today is last day of current month
            if(!this.isLastDay(d)){
                d.setDate(1);
                d.setHours(-1);
            }

            this.lastDayOfLastMonth = d.toLocaleDateString('en-us', {year: 'numeric', month: 'long', day: 'numeric'});
        }
     }
</script>